import React, { useContext, useEffect, useState } from "react";
import { packages } from "../../../types/packagesTypes";
import OpenBookingMenuButton from "../OpenBookingMenuButton";
import EditableTextPackage from "../../editableLiveForm/EditableTextPackage";
import { AuthContext } from "../../../contexts/AuthProvider";
import { v4 as uuidv4 } from "uuid";
import { UserContextType } from "../../../types/userType";
import logoAEQ from "../../../images/aeq.png";

type IProps = {
  selectedPackage: packages;
  hideShowBookingBox: () => void;
};

const PackagePreview: React.FC<IProps> = ({ selectedPackage, hideShowBookingBox }) => {
  const { currentUser, isLoggedContextState } = useContext(AuthContext) as UserContextType;
  const [modeVenteYourte, setmodeVenteYourte] = useState(false);
  const [modeYourte, setmodeYourte] = useState(false);

  useEffect(() => {
    if (selectedPackage.activityType === "yourteSell") {
      setmodeVenteYourte(true);
    }
    if (selectedPackage.activityType === "yourte") {
      setmodeYourte(true);
    }
  }, []);

  return (
    <div className="sm:w-[60%] m-2 sm:m-4 bg-aqs-blue-50/50 border-[1px] border-white rounded-xl">
      <div className="flex flex-col sm:h-full">
        <div className="flex flex-col sm:flex-row sm:h-[75%]">
          <div className=" flex justify-center sm:w-[60%] border-2 border-white overflow-hidden rounded-xl sm:rounded-l-xl">
            <img
              className="hover:scale-125 w-[100%] h-[100%] object-contain hover:opacity-90 transition ease-in-out delay-150 duration-500"
              src={selectedPackage?.picUrl}
              alt={selectedPackage?.name}
            />
          </div>
          <div className="flex flex-col sm:w-[40%] border-2 border-white overflow-y-auto rounded-xl sm:rounded-r-xl p-3 justify-between items-center ">
            <div className="text-2xl sm:text-left font-semibold text-black/60 p-1 ">
              {isLoggedContextState && currentUser.admin === 1 ? (
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={selectedPackage}
                  attToModif={"name"}
                />
              ) : (
                <h2>{selectedPackage?.name.toUpperCase()}</h2>
              )}
            </div>
            <div style={{ whiteSpace: "pre-line" }} className="flex  h-full pb-4 font-josefin">
              {isLoggedContextState && currentUser.admin === 1 ? (
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={selectedPackage}
                  attToModif={"description"}
                />
              ) : (
                <h3>{selectedPackage?.description}</h3>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col sm:flex-row sm:h-[25%] justify-around text-white ">
          <div
            style={{ whiteSpace: "pre-line" }}
            className="flex justify-center items-center h-full sm:w-[30%] justify-center overflow-y-auto text-">
            {isLoggedContextState && currentUser.admin === 1 ? (
              <EditableTextPackage
                key={uuidv4()}
                selectedPackage={selectedPackage}
                attToModif={"inclusions"}
              />
            ) : (
              <p>{selectedPackage?.inclusions}</p>
            )}
          </div>
          <div className="flex flex-col justify-center items-center h-full sm:w-[70%]">
            <div className="p-3 m-3 text-4xl w-fit bg-aqs-blue-300 border-[1px] border-orange text-white rounded-lg">
              {isLoggedContextState && currentUser.admin === 1 ? (
                <EditableTextPackage
                  key={uuidv4()}
                  selectedPackage={selectedPackage}
                  attToModif={"pricing"}
                />
              ) : (
                <p>
                  {modeVenteYourte ? "Min." : ""}
                  {selectedPackage?.pricing}
                  {modeVenteYourte || modeYourte ? "$/Yourte." : "$/Pers."}
                </p>
              )}
            </div>

            <div>
              {modeVenteYourte ? (
                <div></div>
              ) : (
                <div className="w-fit">
                  <OpenBookingMenuButton key={uuidv4()} hideShowBookingBox={hideShowBookingBox} />
                </div>
              )}
            </div>
          </div>

          <div className="flexitems-center justify-center overflow-hidden px-4 ">
            <a
              href="https://www.aventurequebec.ca/entreprise/aventure-quatre-saisons"
              className="flex justify-center"
              target="_blank"
              rel="noopener noreferrer">
              <img
                className="h-40 object-contain"
                id="logoTopMenu"
                src={logoAEQ}
                alt="Membre Aventure Ecotourisme Québec"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PackagePreview;
